import { photographerConstants } from '@/utils/constants/photographerConstants'
import { Account } from './Head'
import { Detail } from './Detail/Table'
import { Expense } from './Expense/Table'

export type PreviewProps = {
  account: Account
  notes: string
  details: Detail[]
  gasUnitPrice: number
  expenses: Expense[]
}

type PreviewStates = {
  detailSubTotal: number
  detailTaxAmount: number
  detailTotalAmount: number
  expenseTotalAmount: number
  totalAmount: number
}

export const usePreviewStates = (props: PreviewProps): PreviewStates => {
  const detailSubTotal = props.details.reduce(
    (acc, detail) => acc + detail.amount + detail.mileage * props.gasUnitPrice,
    0
  )
  const detailTaxAmount = Math.round(
    detailSubTotal * photographerConstants.CONSUMPTION_TAX_RATE
  )
  const detailTotalAmount = detailSubTotal + detailTaxAmount
  const expenseTotalAmount = props.expenses.reduce(
    (acc, expense) => acc + expense.amount,
    0
  )
  const totalAmount = detailTotalAmount + expenseTotalAmount

  return {
    detailSubTotal,
    detailTaxAmount,
    detailTotalAmount,
    expenseTotalAmount,
    totalAmount,
  }
}
