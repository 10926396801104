import { Computable, Emits } from '@/types/utils'
import { computed, reactive, watch } from 'vue'
import {
  InvoiceDetailInput,
  InvoiceDetailTableRowProps,
} from '@/vueComponents/photographer/organisms/pageContent/InvoiceCreatePageContent/InvoiceDetail/InvoiceDetailTableRow'
import { photographerConstants } from '@/utils/constants/photographerConstants'
import {
  handleDeleteRow,
  isContentInputInvalid,
  isDateInputInvalid,
  isAmountInputInvalid,
  isInvoiceCommonInputEmpty,
  isMileageInputInvalid,
} from '@/utils/functions/invoice'

export type InvoiceDetail = {
  id: number | null
  date: string
  content: string
  amount: number | null
  mileage: number | null
  canEdit: boolean
  hasRowError: boolean
  dispatchCameramanId: number | null
}

export type InvoiceDetailTableProps = {
  details: InvoiceDetail[]
  gasUnitPrice: number
}

type InvoiceDetailTableStates = {
  details: InvoiceDetailTableRowProps[]
  subTotal: number
  taxAmount: number
  totalAmountIncludingTax: number
  nextMaximumRowNo: InvoiceDetailTableRowProps['rowNo']
}

export type InvoiceDetailTableEmitParams = {
  'update:details': InvoiceDetail[]
}

export const getTaxAmount = (subTotal: number) => {
  return Math.round(subTotal * photographerConstants.CONSUMPTION_TAX_RATE)
}

export const useInvoiceDetailTableStates = (props: InvoiceDetailTableProps) => {
  const states: InvoiceDetailTableStates = reactive<
    Computable<InvoiceDetailTableStates>
  >({
    details: [],
    subTotal: computed(() =>
      states.details.reduce(
        (acc, detail) =>
          acc +
          Number(detail.detailInput.amount) +
          Number(detail.detailInput.mileage) * props.gasUnitPrice,
        0
      )
    ),
    taxAmount: computed(() => getTaxAmount(states.subTotal)),
    totalAmountIncludingTax: computed(() => states.subTotal + states.taxAmount),
    nextMaximumRowNo: computed(
      () => states.details.reduce((a, b) => (a > b ? a : b)).rowNo + 1
    ),
  })
  return states
}

const getDefaultTableRow = (
  rowNo: InvoiceDetailTableRowProps['rowNo'],
  gasUnitPrice: InvoiceDetailTableRowProps['gasUnitPrice']
): InvoiceDetailTableRowProps => {
  return {
    detailInput: {
      date: '',
      content: '',
      amount: '',
      mileage: '',
    },
    gasUnitPrice,
    id: null,
    rowNo,
    canEdit: true,
    hasRowError: false,
    dispatchCameramanId: null,
  }
}

const isEveryInputEmpty = (detailInput: InvoiceDetailInput): boolean => {
  return isInvoiceCommonInputEmpty(detailInput) && detailInput.mileage === ''
}

const detailsStates = (
  details: InvoiceDetailTableProps['details'],
  gasUnitPrice: InvoiceDetailTableProps['gasUnitPrice']
): InvoiceDetailTableStates['details'] => {
  return details.map((detail: InvoiceDetail, index: number) => {
    return {
      detailInput: {
        date: detail.date,
        content: detail.content,
        amount: detail.amount === null ? '' : String(detail.amount),
        mileage: detail.mileage === null ? '' : String(detail.mileage),
      },
      gasUnitPrice,
      id: detail.id,
      rowNo: index + 1,
      canEdit: detail.canEdit,
      hasRowError: detail.hasRowError,
      dispatchCameramanId: detail.dispatchCameramanId,
    }
  })
}

const watchPropsDetails = (
  states: InvoiceDetailTableStates,
  props: InvoiceDetailTableProps
) => {
  watch(
    () => props.details,
    (newVal: InvoiceDetailTableProps['details']) => {
      states.details = detailsStates(newVal, props.gasUnitPrice)
    },
    {
      deep: true,
      immediate: true,
    }
  )
}

const detailsFilteredByNotEmpty = (
  details: InvoiceDetailTableStates['details']
): InvoiceDetailTableRowProps[] => {
  return details.filter((detail: InvoiceDetailTableRowProps) => {
    return !isEveryInputEmpty(detail.detailInput)
  })
}

const detailsEmitParams = (
  details: InvoiceDetailTableRowProps[]
): InvoiceDetailTableEmitParams['update:details'] => {
  return details.map((detail: InvoiceDetailTableRowProps): InvoiceDetail => {
    return {
      id: detail.id,
      date: replaceToHyphen(detail.detailInput.date),
      content: detail.detailInput.content,
      amount:
        detail.detailInput.amount === ''
          ? null
          : Number(detail.detailInput.amount),
      mileage:
        detail.detailInput.mileage === ''
          ? null
          : Number(detail.detailInput.mileage),
      canEdit: detail.canEdit,
      hasRowError: detail.hasRowError,
      dispatchCameramanId: detail.dispatchCameramanId,
    }
  })
}

const watchStatesDetails = (
  states: InvoiceDetailTableStates,
  emits: Emits<InvoiceDetailTableEmitParams>
) => {
  watch(
    () => states.details,
    (newVal: InvoiceDetailTableStates['details']) => {
      const details = detailsFilteredByNotEmpty(newVal)
      emits('update:details', detailsEmitParams(details))
    },
    {
      deep: true,
      immediate: true,
    }
  )
}

const updateDetailInput = (
  value: InvoiceDetailInput,
  rowNo: InvoiceDetailTableRowProps['rowNo'],
  states: InvoiceDetailTableStates
): void => {
  states.details.forEach((detail: InvoiceDetailTableRowProps) => {
    if (detail.rowNo === rowNo) {
      detail.detailInput.date = value.date
      detail.detailInput.content = value.content
      detail.detailInput.amount = value.amount
      detail.detailInput.mileage = value.mileage
    }
  })
}

const deleteRow = (rowNo: number, states: InvoiceDetailTableStates): void => {
  states.details = handleDeleteRow(states.details, rowNo)
}

const addRow = (
  states: InvoiceDetailTableStates,
  props: InvoiceDetailTableProps
): void => {
  states.details.push(
    getDefaultTableRow(states.nextMaximumRowNo, props.gasUnitPrice)
  )
}

const replaceToSlash = (date: string): string => {
  return date.replace(/-/g, '/')
}

const replaceToHyphen = (date: string): string => {
  return date.replace(/\//g, '-')
}

const handleFormatDate = (date: string, canEdit: boolean): string => {
  if (!canEdit) {
    return replaceToSlash(date)
  }
  return date
}

const isAtLeastOneInputInValid = (detailInput: InvoiceDetailInput): boolean => {
  return (
    isDateInputInvalid(detailInput.date) ||
    isContentInputInvalid(detailInput.content) ||
    isAmountInputInvalid(detailInput.amount) ||
    isMileageInputInvalid(detailInput.mileage)
  )
}

const isInputValid = (detailInput: InvoiceDetailInput): boolean => {
  if (isEveryInputEmpty(detailInput)) {
    return true
  }
  if (isAtLeastOneInputInValid(detailInput)) {
    return false
  }
  return true
}

const validate = (states: InvoiceDetailTableStates): boolean => {
  states.details.forEach(
    (detail: InvoiceDetailTableRowProps, index: number) => {
      if (isInputValid(detail.detailInput)) {
        states.details[index].hasRowError = false
      } else {
        states.details[index].hasRowError = true
      }
    }
  )
  const hasError = states.details.some(
    (detail: InvoiceDetailTableRowProps) => detail.hasRowError === true
  )
  return !hasError
}

type InvoiceDetailTableActions = {
  updateDetailInput: (value: InvoiceDetailInput, rowNo: number) => void
  deleteRow: (rowNo: number) => void
  addRow: () => void
  handleFormatDate: (date: string, canEdit: boolean) => string
  validate: () => boolean
}

export const useInvoiceDetailTableActions = (
  props: InvoiceDetailTableProps,
  states: InvoiceDetailTableStates,
  emits: Emits<InvoiceDetailTableEmitParams>
): InvoiceDetailTableActions => {
  watchPropsDetails(states, props)
  watchStatesDetails(states, emits)

  return {
    updateDetailInput: (value: InvoiceDetailInput, rowNo: number) =>
      updateDetailInput(value, rowNo, states),
    deleteRow: (rowNo: number) => deleteRow(rowNo, states),
    addRow: () => addRow(states, props),
    handleFormatDate: (date: string, canEdit: boolean) =>
      handleFormatDate(date, canEdit),
    validate: () => validate(states),
  }
}
